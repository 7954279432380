var _sentryCollisionFreeGlobalObject = true ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};

_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {
  "id": "2024.10.12-05.05-9ac0857"
};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = ""; // This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { createClientSentryConfig } from '@yoweb/utils/lib/sentry-config';
Sentry.init(createClientSentryConfig());